<template>
  <div class="bulk-align">
    <div style="padding: 15px 15px 0;" v-if="errorHas($options.name) || successHas($options.name) ">
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
    </div>

    <div class="border-bottom py-3" v-if="['spaces', 'tenant_rent_management', 'review_rent_changes'].includes(template) && state !== 'initial' && !config.pivot_mode.type">
      <span class="ml-3 font-weight-medium hb-text-night">
        <span @click="setInitialState" class="hand-pointer">
          <v-icon size="25" color="hb-text-night" class="hand-pointer">mdi-chevron-left</v-icon>
          <label class="tab-label hand-pointer">{{selectedSection.title}}</label>
        </span>
      </span>
    </div>
    <!-- template :{{ template }} </br>
    selectedSection :{{selectedSection}} </br>
    items: {{ items.length }} </br>
    state: {{state}}</br> -->
    <div style="position: relative; height:99%;">
      <index v-if="state === 'initial' && !config.pivot_mode.type"  @close="$emit('close')" :items="items" :config="config" :template="template" @takeAction="takeAction" @generate-doc="generateDocument" @change-billday="changeLeasesBillDay" ></index>

      <component
        @cancel="setInitialState"
        @back="goBack"
        v-if="state !== 'initial' && !config.pivot_mode.type"
        :items="items"
        :is="state"
        :template="template"
        :selected="selectedSection"
        @close-bulk-edit="$emit('close')"
      />

      <div v-if="config.pivot_mode.type" class="ma-3">
        <hb-notification type="caution" not-dismissable>
          Bulk Edit is not currently available when data is grouped.
        </hb-notification>
      </div>
    </div>

  </div>
</template>
<script>
    import Status from '../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import { notificationMixin } from '../../../mixins/notificationMixin';
    import { EventBus } from '../../../EventBus';

    import Vue from 'vue';
    import { mapGetters, mapActions } from 'vuex';
    import BulkEditUnitDetails from '../BulkActions/UnitDetails.vue';
    import BulkEditUnitAttributes from '../BulkActions/UnitAttributes.vue';
    import BulkEditUnitSellRate from '../BulkActions/UnitPrices.vue';
    import BulkEditUnitSetRate from '../BulkActions/UnitSetRate.vue';

    import MergeContacts from '../BulkActions/MergeContacts.vue';
    import BulkChargeFee from '../BulkActions/ChargeFee.vue';
    import SendBulkEmail from '../BulkActions/SendEmail.vue';
    import SendBulkSMS from '../BulkActions/SendSms.vue';
    import BulkRaiseRent from '../BulkActions/RaiseRent.vue';
    import BulkGenerateDocument from '../BulkActions/GenerateDocument.vue';
    import BulkBillingDayChange from '../BulkActions/BulkBillingDayChange.vue';
    import BulkChangeLeadStatus from '../BulkActions/LeadStatus.vue';
    import Index from '../BulkActions/Index.vue';
    import BulkEditFeaturesAndAmenities from '../BulkActions/FeaturesAndAmenities.vue';
    import BulkManualRentChange from '../BulkActions/ManualRentChange.vue';
    import BulkEditRentChange from '../BulkActions/BulkEditRentChange.vue';
    import BulkSkipCancel from '../BulkActions/BulkSkipCancel.vue';
    import BulkTagRentChange from '../BulkActions/BulkTagRentChange.vue';
    import BulkExemptRentChange from '../BulkActions/ExemptRentChange.vue';

    Vue.component('BulkEditUnitDetails', BulkEditUnitDetails);
    Vue.component('BulkEditUnitAttributes', BulkEditUnitAttributes);
    Vue.component('BulkEditUnitSellRate', BulkEditUnitSellRate);
    Vue.component('BulkEditUnitSetRate', BulkEditUnitSetRate);
    Vue.component('MergeContacts', MergeContacts);
    Vue.component('SendBulkEmail', SendBulkEmail);
    Vue.component('SendBulkSMS', SendBulkSMS);
    Vue.component('BulkRaiseRent', BulkRaiseRent);
    Vue.component('BulkChargeFee', BulkChargeFee);
    Vue.component('BulkGenerateDocument', BulkGenerateDocument);
    Vue.component('BulkBillingDayChange', BulkBillingDayChange);
    Vue.component('BulkChangeLeadStatus', BulkChangeLeadStatus);
    Vue.component('BulkEditFeaturesAndAmenities', BulkEditFeaturesAndAmenities);
    Vue.component('BulkManualRentChange', BulkManualRentChange);
    Vue.component('BulkEditRentChange', BulkEditRentChange);
    Vue.component('BulkSkipCancel', BulkSkipCancel);
    Vue.component('BulkTagRentChange', BulkTagRentChange);
    Vue.component('BulkExemptRentChange', BulkExemptRentChange);

    export default {
        name: "BulkEdit",
        mixins: [notificationMixin],
        data() {
            return {
                state: 'initial',
                selected: '',
                selectedSection: {},
            }
        },
        props: ['config','template', 'records', 'items'],
        components: {
            Status,
            Loader,
            Index
        },
        created(){
          EventBus.$on('disablecheckbox:report', this.changeState);
        },
        methods:{
          ...mapActions({
            getBulkContacts : 'reportStore/addBulkContacts'
          }),
          changeState(data){
            this.$emit('disableCheckboxes', data);
          },
          goBack(){
            this.state = 'initial';
          },
          generateDocument(){
            this.state = 'BulkGenerateDocument';
          },
          changeLeasesBillDay(){
            this.state = 'BulkBillingDayChange';
          },
            setInitialState(){
                this.state = 'initial';
                this.$emit('disableCheckboxes', false);
            },

            takeAction(selected, section = {}){

                if(!selected) {
                    this.showMessageNotification({ type:'error', description: 'Please select an action to take.' });
                    return;
                }

                if(!this.items.length) {
                    this.showMessageNotification({ type:'error', description: 'Please select some records to edit.' });
                    return;
                }

                this.selected = selected;
                this.state = this.selected;
                this.selectedSection = section;

                this.$emit('disableCheckboxes', true);

            }
          },
          beforeDestroy(){
            this.getBulkContacts([]);
            EventBus.$off('disablecheckbox:report', this.changeState);
          }
    }

</script>
<style scoped>
  .bulk-align {
    position: absolute;
    top: 53.5px;
    bottom: 0;
    padding: 0;
    width: 100%;

  }

  .tab-label {
    position: relative;
    top: 2px;
  }
</style>
