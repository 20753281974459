<template>
  <div class="editor-container">
    <v-card flat style="height: 100%; overflow: scroll;">
      <div>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

        <p class="font-regular mt-2 px-5">
          Here you can download this report, or send it as an email.
        </p>

      </div>
      <v-container class="hb-table">
        <v-row class="hb-table-row">
          <v-col class="hb-table-label pl-6 pt-4" cols="4">
            Report Format
          </v-col>
          <v-col class="hb-table-value" >
            <v-radio-group class="hb-table-checkbox" hide-detail dense v-model="export_data.format"  mandatory>
              <v-radio label=".pdf" value="pdf"></v-radio>
              <v-radio label=".xlsx" value="xlsx"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row class="hb-table-row">
          <v-col class="hb-table-label pl-6 pt-4" cols="4">
            Action:
          </v-col>
          <v-col class="hb-table-value" >
            <v-radio-group class="hb-table-checkbox" dense v-model="export_data.process">
              <v-radio hide-details dense color="primary"  value="download" label="Download/Print"></v-radio>
<!--              <v-radio hide-details dense color="primary"  value="email" label="Email this report"></v-radio>-->
            </v-radio-group>
            <v-text-field dense v-show="export_data.process === 'email'" hide-details="auto" class="hb-table-lined-input" placeholder="Enter Email" v-model="export_data.email"></v-text-field>
          </v-col>
        </v-row>

<!--        <v-row class="hb-table-row">-->

<!--          <v-col class="hb-table-label pl-6 pt-4" cols="4"></v-col>-->

<!--          <v-col class="hb-table-value" >-->
<!--            <v-radio-group class="hb-table-checkbox" v-model="meta" mandatory>-->
<!--              <v-radio value="now" label="Run A Report Now"></v-radio>-->
<!--              <v-radio value="recurring" label="Schedule a Recurring Report"></v-radio>-->
<!--            </v-radio-group>-->

<!--          </v-col>-->
<!--        </v-row>-->

      </v-container>



    </v-card>

    <v-toolbar flat class="panel-footer">
      <v-spacer></v-spacer>
      <hb-link @click="$emit('close')" class="mr-3">
        Cancel
      </hb-link>

      <hb-btn color="primary" :loading="isLoading($options.name)" @click="generate">
        Export Report
      </hb-btn>
    </v-toolbar>

  </div>
</template>
<script>
    import Vue from 'vue';
    import api from '../../../assets/api.js';
    import Status from '../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment'
    import { mapGetters, mapActions } from "vuex";
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import { calendarMixin } from '@/mixins/calendarMixin.js';


    export default {
        name: "ExportReport",
        mixins: [ notificationMixin, calendarMixin ],
        data() {
            return {
                meta: 'now',
                export_data: {
                    format: 'pdf',
                    email: '',
                    process: 'download',
                    text: '',
                    time_period: '',
                    send_day: ''
                },
                timePeriods:['Daily', 'Weekly', "Monthly", "Custom"],
                reportMeta: ['Run a report now', "Create a recurring report"],
                daysOfWeek: ['Monday', 'Tuesday', "Wednesday","Thursday", "Friday", "Saturday", "Sunday"],
                selectedReport: {}
            }
        },
        props: ['config','template','selected','contact_id'],
        components: {
            Loader
        },
        computed: {
          ...mapGetters({
                geDynamicRunParam: 'reportStore/geDynamicRunParam',
                primaryRoleId: 'authenticationStore/getPrimaryRoleId',
                properties: 'propertiesStore/properties',
                propertyGroups: 'propertiesStore/propertyGroups',
            })
        },
        created() {
          this.filterOutIdFields()
        },
        methods:{
            filterOutIdFields() {
              this.selectedReport = cloneDeep(this.selected)
              let filteredColumns = this.selectedReport.filters.columns.filter(item => {
                return !item.key.endsWith('_id')
              })
              this.selectedReport.filters.columns = filteredColumns
            },
            exportReport(){
            },
            downloadXLSX(report){
              this.generate(JSON.parse(report.filters), 'excel', report.template, report.name)
            },

            downloadPDF(report){
              this.generate(JSON.parse(report.filters), 'pdf', report.template, report.name)
            },

            getTimeframeDates(timeframe) {

              if(!timeframe || !timeframe.label){
                return {
                  start: null,
                  end: moment().endOf('day').format('YYYY-MM-DD')
                }
              }
              switch(timeframe.label.toLowerCase()) {
                case 'today':
                  return {
                    start: moment().startOf('day').format('YYYY-MM-DD'),
                    end: moment().endOf('day').format('YYYY-MM-DD')
                  }
                case 'yesterday':
                  return {
                    start: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
                    end: moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
                  }
                case 'last 7 days':
                  return {
                    start: moment().subtract(6, 'day').startOf('day').format('YYYY-MM-DD'),
                    end: moment().endOf('day').format('YYYY-MM-DD')
                  }
                case 'last 30 days':
                  return {
                    start: moment().subtract(29, 'day').startOf('day').format('YYYY-MM-DD'),
                    end: moment().endOf('day').format('YYYY-MM-DD')
                  }
                case 'this month':
                  return {
                    start: moment().startOf('month').format('YYYY-MM-DD'),
                    end: moment().endOf('day').format('YYYY-MM-DD')
                  }
                case 'last month':
                  return {
                    start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                    end: moment().subtract(1, 'month').endOf('month').endOf('day').format('YYYY-MM-DD')
                  }
                case 'year to date':
                  let start = this.getFirstDayOfYear();
                  return {
                    start,
                    end: moment().endOf('day').format('YYYY-MM-DD')
                  }
                
                case 'custom date':
                case 'custom range':
                  return {
                    start: timeframe.start_date ? moment(timeframe.start_date).startOf('day').format('YYYY-MM-DD') : '',
                    end: timeframe.end_date ? moment(timeframe.end_date).endOf('day').format('YYYY-MM-DD') : ''
                  }

                default:
                  return {
                    start: null,
                    end: moment().endOf('day').format('YYYY-MM-DD')
                  }
              }
            },

            async generate(){
              let url;
              var data = {};
              let response = {};
              let {limit, offset, ...filters} = this.selected.filters;
              let reportPeriodFromStore = this.geDynamicRunParam?.timePeriodConfig?.report_period;
              let reportDateFromStore = this.geDynamicRunParam?.timePeriodConfig?.report_date;
              let reportPeriod = filters?.search?.report_period;
              let reportDate = filters?.search?.report_date;
              filters.current_date = moment().format("YYYY-MM-DD");
                //this.isLoading = true
              if(this.selected?.template === 'review_rent_changes')
                filters.search['rent_change_deployment_month'] = this.geDynamicRunParam?.search?.rent_change_deployment_month
              if(this.selected?.template === 'space_group_spaces')
                filters.search['space_group_id'] = this.geDynamicRunParam?.search?.space_group_id
              if(this.selected?.template === 'national_account_unit_list'){
                  filters.search['contact_id'] = this.contact_id
                }
              if (reportDateFromStore) {
                filters.search.report_date = reportDateFromStore;
              }
              if (reportPeriodFromStore) {
                filters.search.report_period = reportPeriodFromStore;
              }

              let filename = this.selected.name;
              if(Object.keys(this.geDynamicRunParam).length && this.$route.query.dynamicRun) {

                if (reportDateFromStore) {
                  filters.search.report_date = reportDateFromStore;
                }
                if (reportPeriodFromStore) {
                  filters.search.report_period = reportPeriodFromStore;
                }

                if(this.geDynamicRunParam.property_group_id) {
                  let property_group = this.propertyGroups.find(pg => pg.id == this.geDynamicRunParam.property_group_id);
                  filename += ` - ${property_group.name}`
                }
                else if(this.geDynamicRunParam.propertyIDArray?.length == 1) {
                  let property = this.properties.find(p => p.id == this.geDynamicRunParam.propertyIDArray[0]);
                  filename += ` - ${property.name}`
                }
                else
                  filename += ' - Multiple Properties';
              }

              if(reportPeriod && reportPeriodFromStore) {
                let report_period = this.getTimeframeDates(reportPeriodFromStore);
                filename += ` - (${report_period.start}${report_period.end ? " - " + report_period.end : ""})`;
              }
              else if(reportDate && reportDateFromStore)
                filename += ` - ${reportDateFromStore}`;
              else {
                filename += ` - ${moment().format('YYYY-MM-DD')}`;
              }

              filename = filename.replace(/[/\\?.*:|"<>]/g, '');

              if (this.export_data.format === 'pdf') {

                if(this.selected?.template === 'tenant_rent_management')
                  filters.columns = filters.columns.filter(obj => !obj.key.endsWith('_id') && obj.key !== 'tenant_space_group')

                if(filters.columns.length > 20) {
                  this.showMessageNotification({description: '20 or less columns should be selected to export pdf report' });
                  return;
                }
                url = api.REPORTS + "download-pdf";
                let timeZone = moment.tz.guess();
                data = {
                  filters: JSON.stringify(filters),
                  type: 'generic',
                  report_type: this.selected.template,
                  format: 'web',
                  name: this.selected.name,
                  timeZone: timeZone,
                  roles_id: [this.primaryRoleId]
                }
                if(Object.keys(this.geDynamicRunParam).length && this.$route.query.dynamicRun) {
                  data['properties'] = this.geDynamicRunParam.propertyIDArray;
                  data['property_group_id'] = this.geDynamicRunParam.property_group_id;
                }

                response = await api.post(this, url, data);
                if(response.socket) {
                  const payload = {
                    type: 'document_download',
                    title: 'Generating Document',
                    persistent: true,
                    loading: true,
                    description: 'We are generating your document, it should begin downloading shortly.',
                    progress: true
                  }
                  this.showMessageNotification(payload);
                } else {
                  this.showMessageNotification({ type: 'success', description: 'Your download should begin shortly.' });
                  const arr = new Uint8Array(response.data);
                  var blob = new Blob([arr], {type: 'application/pdf' });
                  var link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = filename;
                  link.click();
                }

              } else {

                url =  api.REPORTS + "generate";

                data = {
                    filters,
                    type: this.selected.template,
                    format: this.export_data.format,
                    name: this.selected.name,
                    roles_id: [this.primaryRoleId]
                }
                if(Object.keys(this.geDynamicRunParam).length && this.$route.query.dynamicRun) {
                  data['properties'] = this.geDynamicRunParam.propertyIDArray;
                  data['property_group_id'] = this.geDynamicRunParam.property_group_id;
                }

                try {
                  this.showMessageNotification({ type: 'success', description: 'Your download should begin shortly.' });
                  response = await this.$http.post(url, data, { responseType: 'arraybuffer' });
                  var blob = new Blob([response.data], {type: response.headers.get('content-type')});

                  var link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = filename;
                  link.click();
                } catch(err) {
                    this.showMessageNotification({ description: err });
                }


              }

            }
        }
    }

</script>

<style scoped>
  /*.hb-table{*/
  /*  border: 1px solid #DFE3E8;*/
  /*}*/
  /*.hb-table-row{*/
  /*  border-bottom: 1px solid #DFE3E8;*/
  /*}*/
  /*.hb-table-row:last-child{*/
  /*  border-bottom: none;*/
  /*}*/
  /*.hb-table-label pl-6 pt-4{*/
  /*  background: #F4F6F8;*/
  /*}*/
  /*.editor-container{*/
  /*  position: absolute;*/
  /*  top: 55px;*/
  /*  bottom: 0;*/
  /*  !* overflow: scroll; *!*/
  /*  padding-bottom: 64px;*/
  /*  width: 100%;*/
  /*}*/
  /*.panel-footer{*/
  /*  border-top: 1px solid #DFE3E8;*/
  /*  z-index: 100;*/
  /*  position: absolute;*/
  /*  bottom: 0;*/
  /*  width: 100%;*/
  /*}*/
  /*.v-expansion-panels .v-expansion-panel.v-expansion-panel--active > .v-expansion-panel-header {*/
  /*  min-height: auto;*/
  /*}*/
</style>
